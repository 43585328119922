<template>
	<div>
		<van-nav-bar
			title="預約條款"
			fixed
			left-arrow
			class="qjc-nav-bar"
			 @click-left="$router.back()"/>
		<div class="clause">
			<van-image class="hpv_img" src="https://medin.oss-cn-shanghai.aliyuncs.com/2023/03/09/clause_hpv.png"></van-image>
			<div class="tips">以上條款，請您知悉，如您購買，即默認接受條款。後續事宜將嚴格按照以上條款進行，謝謝!</div>
			<div class="foot">
				<van-button class="btn" @click="handleSub">我知道了</van-button>
			</div>
		</div>
		
		
		
	</div>
</template>

<script>
	import { isPay } from '@/js/axios'
	
	import Vue from 'vue'
	import { NavBar, Image, Cell, Button, Tabbar, TabbarItem, Toast, Popup, Dialog, Field } from 'vant'
	Vue.use(NavBar)
	   .use(Image)
	   .use(Cell)
	   .use(Button)
	   .use(Tabbar)
	   .use(TabbarItem)
	   .use(Toast)
	   .use(Popup)
	   .use(Field)
	   .use(Dialog);
	
	export default{
		name: 'vaccinesLogin',
		data (){
			return {
				
			}
		},
		computed: {
			
		},
		mounted() {
			let clientWidth = document.documentElement.clientWidth;
			let clientHeight = document.documentElement.clientHeight;
			let ratio = clientWidth / 375;
			let list_height = clientHeight - (3.26 * 100 * ratio / 2);
			
			document.querySelector('body').setAttribute('style', 'background-color:#F5F7FA')
		},
		beforeDestroy() {
			document.querySelector('body').removeAttribute('style')
		},
		methods: {
			handleSub() {
				
			},
		}
	}
</script>

<style lang="scss" scoped>
	.clause{
		width: 100%;
		min-height: 100vh;
		padding: 0.88rem 0;
		background-color: #fff;
		text-align: left;
	}
	.hpv_img {
	    width: 100%;
	    height: 100%;
	}
	
	.foot {
	    width: 100%;
	    margin-top: 0.80rem;
	    padding: 0 0.30rem;
	}
	.btn {
	    width: 100% !important;
	    height: 0.88rem;
	    font-size: 0.40rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #FFFCFC;
	    background-color: #6681FA;
	    border-radius: 0.16rem;
	}
	
	.tips {
	    width: 100%;
	    padding: 0.22rem 0.30rem;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #4584E3;
	    line-height: 0.38rem;
	    background-color: #EBF2FE;
	    margin-top: -0.10rem;
	}
	
</style>
